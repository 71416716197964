var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "card", style: { height: this.cardHeight } },
    [
      _c("div", { staticClass: "card-body" }, [
        _c("h5", { staticClass: "card-title" }, [
          _vm._v(
            " " +
              _vm._s(_vm.$t("datafileReport.quotaSettingWidget.title")) +
              " "
          ),
        ]),
        this.isLoading
          ? _c("div", { staticClass: "row justify-content-center" }, [
              _c(
                "div",
                { staticClass: "col-6 text-center pt-5" },
                [
                  _c("b-spinner", {
                    staticClass: "m-2",
                    staticStyle: { width: "5rem", height: "5rem" },
                    attrs: { variant: "mid", role: "status" },
                  }),
                ],
                1
              ),
            ])
          : _vm._e(),
        !this.quotaSettingsNotSaved && this.targetQuotaSettingsPercentage
          ? _c("div", { staticClass: "card-text mt-5" }, [
              _vm._v(
                " " +
                  _vm._s(
                    _vm.$t("datafileReport.quotaSettingWidget.description")
                  ) +
                  " "
              ),
              _c(
                "div",
                { staticClass: "mt-3" },
                [
                  _c(
                    "b-progress",
                    {
                      staticClass: "mt-4",
                      staticStyle: { "font-size": "15px" },
                      attrs: { max: 100, height: "30px" },
                    },
                    [
                      _c("b-progress-bar", {
                        attrs: {
                          value:
                            (this.targetQuotaSettingsPercentage[0] /
                              this.targetQuotaSettingsPercentage[1]) *
                            100,
                          label: `${this.targetQuotaSettingsPercentage[0]} / ${this.targetQuotaSettingsPercentage[1]}`,
                          variant: "success",
                          "show-value": "",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ])
          : _vm._e(),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }