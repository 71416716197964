<script>
/* eslint-disable no-unused-vars */
import appConfig from "@/app.config";
import QuotaSettingsInfoModal from "@/redem/datafiles/components/modals/quota-settings-info-modal.vue";
import CertificationLevelsModal from "@/redem/report/components/modals/certification-levels-modal.vue";
import DataRecordsWidget from "@/redem/report/components/widgets/data-records-widget.vue";
import DistributionWidget from "@/redem/report/components/widgets/distribution-widget.vue";
import MetadataWidget from "@/redem/report/components/widgets/metadata-widget.vue";
import QualitySignWidget from "@/redem/report/components/widgets/quality-sign-widget.vue";
import QuotaSettingsWidget from "@/redem/report/components/widgets/quota-settings-widget.vue";
import RScoreWidget from "@/redem/report/components/widgets/r-score-widget.vue";
import TrafficLightWidget from "@/redem/report/components/widgets/traffic-light-widget.vue";
import Layout from "@/router/layouts/report-layout.vue";
// Modals
import DatafileService from "@/api/services/datafile.service.js";
import DatafileReportService from "@/api/services/datafileReport.service.js";
import PasswordModal from "@/redem/report/components/modals/password-modal.vue";
import QualityScoreModal from "@/redem/report/components/modals/quality-score-modal.vue";
import LoadingCircle from "@/shared/components/model/loading-circle.vue";
import { EventBus } from "@/shared/util/event-bus.js";
// Enums
import LiveCleaningStatus from "@/shared/enums/liveCleaningStatus.js";
import {
  decryptReportPassword,
  encryptReportPassword
} from "../../../shared/crypto/crypto-helpers";
import { GLOBALS } from "../../../shared/util/globals";

export default {
  page: {
    meta: [{ name: "description", content: appConfig.description }]
  },
  components: {
    Layout,
    MetadataWidget,
    QualitySignWidget,
    RScoreWidget,
    DistributionWidget,
    TrafficLightWidget,
    QuotaSettingsWidget,
    QuotaSettingsInfoModal,
    DataRecordsWidget,
    QualityScoreModal,
    PasswordModal,
    CertificationLevelsModal,
    LoadingCircle
  },
  data() {
    return {
      datafileId: null,
      certificateCode: null,
      certificateLevel: null,
      certificateImageURL: null,
      /******** Operational Variable ******/
      showQuotaSettingsInfoModel: false,
      isDisplayQuotaSettingsModal: false,
      isDisplayCertificateWidget: false,
      isDisplayQualityScoreModal: false,
      isDisplayQuotaWidget: true,
      passwordLoading: true,
      passwordRequired: false,
      passwordConfirmed: false,
      metadataWidgetWidth: "col-md-12 col-lg-12",
      targetQuotaSettingsPercentage: [],
      isDisplayCertificationLevelModal: false,
      isDisplayQualityScoreWidgets: false,
      isDisplayQualityScoreWidgetsSet: false,
      isAlgorithmsInDatafileSet: false,
      algorithmsInDatafile: {},
      liveStatus: null,
      liveCleaningStatus: LiveCleaningStatus,
      liveStatusAlert: true,
      passwordIncorrect: false,
      displayShortReport: false
    };
  },
  async created() {
    this.datafileId = this.$route.query.datafileId;
    await this.setAlgorithmsInDatafile();
    EventBus.$on("showQuotaSettingsModal", () => {
      this.isDisplayQuotaSettingsModal = true;
    });

    await this.checkReportPasswordRequired();
  },
  mounted() {
    const savedpassword = window.sessionStorage.getItem(
      "REPORT_PASSWORD_" + this.datafileId
    );
    if (savedpassword) {
      const decryptedPassword = decryptReportPassword(savedpassword);
      this.checkPassword(decryptedPassword);
    }
  },
  methods: {
    async checkReportPasswordRequired() {
      this.passwordRequired = await DatafileReportService.checkReportPasswordRequired(
        this.datafileId
      );

      this.displayShortReport = await DatafileReportService.checkReportTypeIsShort(
        this.datafileId
      );
      this.passwordLoading = false;
    },
    showQuotaSettingsInfo(show) {
      this.showQuotaSettingsInfoModel = show;
    },
    showQualityScoreModal() {
      this.isDisplayQualityScoreModal = true;
    },
    async checkPassword(pw) {
      const reportPasswordValid = await DatafileReportService.checkReportPassword(
        this.datafileId,
        pw
      );
      if (reportPasswordValid) {
        console.log("report password is correct");
        this.passwordConfirmed = true;
        this.passwordIncorrect = false;
        let title = `Data Quality Report | Loading... | Redem`;
        document.title = title;
        const encryptedPassword = encryptReportPassword(pw);
        window.sessionStorage.setItem(
          "REPORT_PASSWORD_" + this.datafileId,
          encryptedPassword
        );
        GLOBALS.reportPassword = pw;
      } else {
        this.passwordIncorrect = true;
        console.log("report password is incorrect.");
      }
    },
    setCertCode(code) {
      this.certificateCode = code;
      if (code) {
        this.isDisplayCertificateWidget = true;
        this.certificateCode = code;
        this.metadataWidgetWidth = "col-lg-9";
      }
    },
    setCertLevel(level) {
      this.certificateLevel = level;
    },
    setCertificateLevelURL(URL) {
      this.certificateImageURL = URL;
    },
    setIsDisplayQualityScoreWidgets(value) {
      this.isDisplayQualityScoreWidgetsSet = true;
      this.isDisplayQualityScoreWidgets = value;
    },
    showCertificationLevelModal() {
      this.isDisplayCertificationLevelModal = true;
    },
    async setAlgorithmsInDatafile() {
      this.algorithmsInDatafile = await DatafileService.getAlgorithmsInDatafile(
        this.datafileId
      );
      this.isAlgorithmsInDatafileSet = true;
    },
    async setLiveStatus(liveStatus) {
      this.liveStatus = liveStatus;
    },
    setMetadataWidgetWidth(width) {
      this.metadataWidgetWidth = width;
    }
  }
};
</script>

<template>
  <Layout>
    <div v-if="!passwordLoading && (passwordConfirmed || !passwordRequired)">
      <!-- Title -->
      <div class="row">
        <div class="col-md-12">
          <div
            class="
              page-title-box
              d-flex
              align-items-center
              justify-content-between
            "
          >
            <h4 class="mb-0 font-size-18">{{ $t("datafileReport.title") }}</h4>
          </div>
        </div>
      </div>

      <!-- Live Status Alert -->
      <b-alert
        v-model="liveStatusAlert"
        v-if="liveStatus === liveCleaningStatus.running"
        variant="info"
      >
        {{ $t("datafileReport.liveSurveyRunningInfoText") }}
      </b-alert>

      <!-- Shorter Verion of Report -->
      <div class="row justify-content-center" v-if="displayShortReport">
        <div class="col-lg-9">
          <MetadataWidget
            :datafileId="this.datafileId"
            @setCertificateCode="setCertCode"
            @setCertificateLevel="setCertLevel"
            @setCertificateImageURL="setCertificateLevelURL"
            @setIsDisplayQualityScoreWidgets="setIsDisplayQualityScoreWidgets"
            @setLiveStatus="setLiveStatus"
          />
        </div>
        <div class="col-lg-3">
          <QualitySignWidget
            :datafileId="this.datafileId"
            :certificateCode="certificateCode"
            :certificateLevel="certificateLevel"
            :certificateImageURL="certificateImageURL"
            @displayCertificationLevelModal="showCertificationLevelModal()"
          />
        </div>
      </div>

      <!-- Long Verion of Report -->
      <div v-if="!displayShortReport">
        <!-- Header -->
        <div class="row">
          <!-- Meta Data -->
          <div :class="this.metadataWidgetWidth">
            <MetadataWidget
              :datafileId="this.datafileId"
              @setCertificateCode="setCertCode"
              @setCertificateLevel="setCertLevel"
              @setCertificateImageURL="setCertificateLevelURL"
              @setIsDisplayQualityScoreWidgets="setIsDisplayQualityScoreWidgets"
              @setLiveStatus="setLiveStatus"
            />
          </div>

          <!-- Quality Sign -->
          <div class="col-md-4 col-lg-3" v-if="isDisplayCertificateWidget">
            <QualitySignWidget
              :datafileId="this.datafileId"
              :certificateCode="certificateCode"
              :certificateLevel="certificateLevel"
              :certificateImageURL="certificateImageURL"
              @displayCertificationLevelModal="showCertificationLevelModal()"
            />
          </div>

          <!-- Quota Settings  -->
          <div class="col-md-8 col-lg-4" v-if="isDisplayQuotaWidget">
            <QuotaSettingsWidget
              :datafileId="this.datafileId"
              @showQuotaInfo="showQuotaSettingsInfo(true)"
              @hideQuotaWidget="isDisplayQuotaWidget = false"
              @setMetadataWidgetWidth="setMetadataWidgetWidth"
            />
          </div>
        </div>

        <!-- Overview Infomation -->
        <div class="row">
          <!-- R-Score Card -->
          <div class="col-md-12 col-lg-4">
            <RScoreWidget
              :datafileId="this.datafileId"
              :isDisplayQualityScoreWidgets="isDisplayQualityScoreWidgets"
              @displayplayQualityScoreModal="showQualityScoreModal()"
            />
          </div>

          <!-- Distribution Card -->
          <div class="col-md-12 col-lg-4">
            <DistributionWidget :datafileId="this.datafileId" />
          </div>

          <!-- traffic Light Card -->
          <div class="col-md-12 col-lg-4">
            <TrafficLightWidget :datafileId="this.datafileId" />
          </div>
        </div>

        <!-- Data Record Table  -->
        <div
          class="row"
          v-if="isDisplayQualityScoreWidgetsSet && isAlgorithmsInDatafileSet"
        >
          <div class="col-md-12">
            <DataRecordsWidget
              :datafileId="this.datafileId"
              :isDisplayQualityScoreWidgets="isDisplayQualityScoreWidgets"
              :algorithmsInDatafile="algorithmsInDatafile"
            />
          </div>
        </div>
      </div>
    </div>

    <!-- Password Modal -->
    <div v-if="!passwordLoading && passwordRequired && !passwordConfirmed">
      <PasswordModal
        :visible="passwordRequired === true"
        :passwordIncorrect="passwordIncorrect"
        @submitPassword="checkPassword"
      />
    </div>

    <!-- Loading -->
    <LoadingCircle v-if="passwordLoading" />

    <!-- Quota Settings Info widget Model -->
    <QuotaSettingsInfoModal
      v-if="showQuotaSettingsInfoModel"
      :visible="showQuotaSettingsInfoModel"
      @close="showQuotaSettingsInfo(false)"
    />

    <!-- Quality Score Modal -->
    <QualityScoreModal
      v-if="isDisplayQualityScoreModal"
      :visible="isDisplayQualityScoreModal"
      :datafileId="datafileId"
      @close="isDisplayQualityScoreModal = false"
    />

    <!-- Certification Levels Modal -->
    <CertificationLevelsModal
      v-if="isDisplayCertificationLevelModal"
      :visible="isDisplayCertificationLevelModal"
      @close="isDisplayCertificationLevelModal = false"
    />
  </Layout>
</template>
