var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticStyle: { "overflow-x": "hidden" } }, [
    _c(
      "div",
      { attrs: { id: "layout-wrapper" } },
      [
        _c("ReportTopbar"),
        _c("div", {}, [
          _c("div", { staticClass: "page-content" }, [
            _c(
              "div",
              { staticClass: "container-fluid" },
              [_vm._t("default")],
              2
            ),
          ]),
        ]),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }