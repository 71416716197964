var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "card" }, [
    _c("div", { staticClass: "card-body" }, [
      _c("div", { staticClass: "card-title" }, [
        _vm._v(
          " " + _vm._s(_vm.$t("datafileReport.respondentTable.title")) + " "
        ),
      ]),
      _c("div", [
        _c("span", { staticClass: "mr-2" }, [
          _c("i", {
            staticClass:
              "bx bx-check-circle text-success font-size-20 align-bottom",
          }),
          _vm._v(
            " " + _vm._s(_vm.$t("datafileReport.respondentTable.legend2")) + " "
          ),
        ]),
        _c("span", { staticClass: "mr-2" }, [
          _c("i", {
            staticClass: "bx bx-x-circle text-danger font-size-20 align-bottom",
          }),
          _vm._v(
            " " + _vm._s(_vm.$t("datafileReport.respondentTable.legend3")) + " "
          ),
        ]),
        _c("span", [
          _c("i", {
            staticClass:
              "bx bx-minus-circle text-warning font-size-20 align-bottom",
          }),
          _vm._v(
            " " + _vm._s(_vm.$t("datafileReport.respondentTable.legend1")) + " "
          ),
        ]),
      ]),
      _c("div", { staticClass: "card-text" }, [
        _c("div", { staticClass: "row mt-4" }, [
          _c("div", { staticClass: "col-md-12 text-center" }, [
            _c(
              "div",
              { staticClass: "table-responsive mb-0" },
              [
                _c("b-table", {
                  attrs: {
                    items: _vm.dataForTable,
                    fields: _vm.fieldsForTable,
                    filter: _vm.filter,
                    responsive: "sm",
                    "per-page": _vm.perPage,
                    "current-page": _vm.currentPage,
                    "sort-by": _vm.sortBy,
                    "sort-desc": _vm.sortDesc,
                  },
                  on: {
                    "update:sortBy": function ($event) {
                      _vm.sortBy = $event
                    },
                    "update:sort-by": function ($event) {
                      _vm.sortBy = $event
                    },
                    "update:sortDesc": function ($event) {
                      _vm.sortDesc = $event
                    },
                    "update:sort-desc": function ($event) {
                      _vm.sortDesc = $event
                    },
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "cell(isIncluded)",
                      fn: function (data) {
                        return [
                          !_vm.dbDatafile.isCleaned
                            ? _c("i", {
                                staticClass:
                                  "bx bx-minus-circle text-warning font-size-20",
                              })
                            : _vm._e(),
                          _vm.dbDatafile.isCleaned && !data.value
                            ? _c("i", {
                                staticClass:
                                  "bx bx-x-circle text-danger font-size-20",
                              })
                            : _vm._e(),
                          _vm.dbDatafile.isCleaned && data.value
                            ? _c("i", {
                                staticClass:
                                  "bx bx-check-circle text-success font-size-20",
                              })
                            : _vm._e(),
                        ]
                      },
                    },
                    {
                      key: "cell(rScore)",
                      fn: function (data) {
                        return [
                          _c("span", [
                            _vm._v(
                              " " +
                                _vm._s(
                                  data.value.toLocaleString("de-DE", {
                                    maximumFractionDigits: 2,
                                  })
                                ) +
                                " "
                            ),
                          ]),
                        ]
                      },
                    },
                    {
                      key: "cell(pScore)",
                      fn: function (data) {
                        return [
                          data.value === -999
                            ? _c("span", [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$t(
                                        "datafileResultGeneral.dataRecordTable.notAvailable"
                                      )
                                    ) +
                                    " "
                                ),
                              ])
                            : _c("span", [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      data.value.toLocaleString("de-DE", {
                                        maximumFractionDigits: 2,
                                      })
                                    ) +
                                    " "
                                ),
                              ]),
                        ]
                      },
                    },
                    {
                      key: "cell(cScore)",
                      fn: function (data) {
                        return [
                          data.value === -999
                            ? _c("span", [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$t(
                                        "datafileResultGeneral.dataRecordTable.notAvailable"
                                      )
                                    ) +
                                    " "
                                ),
                              ])
                            : _c("span", [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      data.value.toLocaleString("de-DE", {
                                        maximumFractionDigits: 2,
                                      })
                                    ) +
                                    " "
                                ),
                              ]),
                        ]
                      },
                    },
                    {
                      key: "cell(iScore)",
                      fn: function (data) {
                        return [
                          data.value === -999
                            ? _c("span", [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$t(
                                        "datafileResultGeneral.dataRecordTable.notAvailable"
                                      )
                                    ) +
                                    " "
                                ),
                              ])
                            : _c("span", [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      data.value.toLocaleString("de-DE", {
                                        maximumFractionDigits: 2,
                                      })
                                    ) +
                                    " "
                                ),
                              ]),
                        ]
                      },
                    },
                    {
                      key: "cell(timeScore)",
                      fn: function (data) {
                        return [
                          data.value === -999
                            ? _c("span", [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$t(
                                        "datafileResultGeneral.dataRecordTable.notAvailable"
                                      )
                                    ) +
                                    " "
                                ),
                              ])
                            : _c("span", [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      data.value.toLocaleString("de-DE", {
                                        maximumFractionDigits: 2,
                                      })
                                    ) +
                                    " "
                                ),
                              ]),
                        ]
                      },
                    },
                    {
                      key: "cell(ibScore)",
                      fn: function (data) {
                        return [
                          data.value === -999
                            ? _c("span", [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$t(
                                        "datafileResultGeneral.dataRecordTable.notAvailable"
                                      )
                                    ) +
                                    " "
                                ),
                              ])
                            : _c("span", [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      data.value.toLocaleString("de-DE", {
                                        maximumFractionDigits: 2,
                                      })
                                    ) +
                                    " "
                                ),
                              ]),
                        ]
                      },
                    },
                    {
                      key: "cell(oeScore)",
                      fn: function (data) {
                        return [
                          data.value === -999
                            ? _c("span", [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$t(
                                        "datafileResultGeneral.dataRecordTable.notAvailable"
                                      )
                                    ) +
                                    " "
                                ),
                              ])
                            : _c("span", [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      data.value.toLocaleString("de-DE", {
                                        maximumFractionDigits: 2,
                                      })
                                    ) +
                                    " "
                                ),
                              ]),
                        ]
                      },
                    },
                    {
                      key: "cell(weight)",
                      fn: function (data) {
                        return [
                          data.value === -999
                            ? _c("span", [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$t(
                                        "datafileResultGeneral.dataRecordTable.notAvailable"
                                      )
                                    ) +
                                    " "
                                ),
                              ])
                            : _c("span", [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      data.value.toLocaleString("de-DE", {
                                        maximumFractionDigits: 2,
                                      })
                                    ) +
                                    " "
                                ),
                              ]),
                        ]
                      },
                    },
                    {
                      key: "cell(cleaningMethod)",
                      fn: function (data) {
                        return [
                          data.value === _vm.dataCleaningMethods.QUOTA_TARGET
                            ? _c("span", [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$t(
                                        "datafileReport.respondentTable.excluedReason.quota"
                                      )
                                    ) +
                                    " "
                                ),
                              ])
                            : _vm._e(),
                          data.value === _vm.dataCleaningMethods.MANUAL
                            ? _c("span", [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$t(
                                        "datafileReport.respondentTable.excluedReason.manual"
                                      )
                                    ) +
                                    " "
                                ),
                              ])
                            : _vm._e(),
                          data.value === _vm.dataCleaningMethods.SCORE_THRESHOLD
                            ? _c("span", [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$t(
                                        "datafileReport.respondentTable.excluedReason.scoreThreshold"
                                      )
                                    ) +
                                    " "
                                ),
                              ])
                            : _vm._e(),
                          data.value === _vm.dataCleaningMethods.TARGET_SAMPLE
                            ? _c("span", [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$t(
                                        "datafileReport.respondentTable.excluedReason.targetSample"
                                      )
                                    ) +
                                    " "
                                ),
                              ])
                            : _vm._e(),
                        ]
                      },
                    },
                  ]),
                }),
              ],
              1
            ),
          ]),
        ]),
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col" }, [
            _c(
              "div",
              {
                staticClass:
                  "dataTables_paginate paging_simple_numbers float-right",
              },
              [
                _c(
                  "ul",
                  { staticClass: "pagination pagination-rounded mb-0" },
                  [
                    _c("b-pagination", {
                      attrs: {
                        "total-rows": _vm.rows,
                        "per-page": _vm.perPage,
                      },
                      model: {
                        value: _vm.currentPage,
                        callback: function ($$v) {
                          _vm.currentPage = $$v
                        },
                        expression: "currentPage",
                      },
                    }),
                  ],
                  1
                ),
              ]
            ),
          ]),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }