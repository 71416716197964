<script>
export default {
  props: {
    visible: Boolean
  },
  data() {
    return {};
  },
  computed: {
    showModal: {
      get() {
        return this.visible;
      },
      set(value) {
        if (!value) {
          this.$emit("close");
        }
      }
    }
  },
  methods: {
    closeMode() {
      this.$emit("close");
    }
  }
};
</script>

<template>
  <b-modal centered hide-footer hide-header v-model="showModal" size="lg">
    <div class="row">
      <!-- Title -->
      <div class="col-md-12 mb-3">
        <div class="float-right">
          <i
            class="bx bx-x font-size-20"
            style="cursor: pointer"
            @click="closeMode()"
          ></i>
        </div>
        <h5 class="">{{ $t("datafileReport.certificateInfoModal.title") }}</h5>
      </div>

      <!-- Description -->
      <div class="col-md-12 mb-3">
        {{ $t("datafileReport.certificateInfoModal.description") }}
      </div>

      <!-- Basic Level -->
      <div class="col-md-12">
        <strong>Standard</strong>
        <p>
          {{ $t("datafileReport.certificateInfoModal.level1") }}
           <ul>
            <li>1 x Time-Score </li>
            <li>2 x Open-Ended-Score</li>
          </ul>
        </p>
      </div>

      <!-- Advance Level -->
      <div class="col-md-12">
        <strong>Standard Plus</strong>
        <p>
           {{ $t("datafileReport.certificateInfoModal.level2") }}
           <ul>
            <li>1 x Time-Score </li>
            <li>2 x Open-Ended-Score </li>
            <li>1 x Grid-Question-Score </li>
          </ul>
        </p>
      </div>

      <!-- Ultimate Level -->
      <div class="col-md-12">
        <strong>Extended</strong>
        <p>
           {{ $t("datafileReport.certificateInfoModal.level3") }}
           <ul>
            <li>1 x Time-Score </li>
            <li>2 x Open-Ended-Score </li>
            <li>2 x Grid-Question-Score </li>
            <li>1 x Prediction-Score </li>
            <li>1 x Certainty-Score </li>
          </ul>
        </p>
      </div>


      <!-- Score Descriptions -->
      <div class="col-md-12">
        <hr/>
        <p>
           <ul>
            <li><strong>Time-Score</strong> {{ $t('datafileReport.scoreThresholdInfoModal.timeScore') }}</li>
            <li><strong>Open-Ended-Score</strong> {{ $t('datafileReport.scoreThresholdInfoModal.openEndedScore') }}</li>
            <li><strong>Item-Battery-Score</strong> {{ $t('datafileReport.scoreThresholdInfoModal.itemBatteryScore') }}</li>
            <li><strong>Prediction-Score</strong> {{ $t('datafileReport.scoreThresholdInfoModal.predictionScore') }}</li>
            <li><strong>Certainty-Score</strong> {{ $t('datafileReport.scoreThresholdInfoModal.certaintyScore') }}</li>
          </ul>
        </p>
      </div>

    </div>
  </b-modal>
</template>
