<script>
import DatafileService from "@/api/services/datafile.service.js";
import CertinityScoreWidget from "@/redem/report/components/widgets/quality-score-widgets/cs-widget.vue";
import ItemBatteryScoreWidget from "@/redem/report/components/widgets/quality-score-widgets/ibs-widget.vue";
import InformationScoreWidget from "@/redem/report/components/widgets/quality-score-widgets/is-widget.vue";
import OpenEndedScoreWidget from "@/redem/report/components/widgets/quality-score-widgets/oes-widget.vue";
import PredictionScoreWidget from "@/redem/report/components/widgets/quality-score-widgets/ps-widget.vue";
import SocialDesirabilityScoreWidget from "@/redem/report/components/widgets/quality-score-widgets/sds-widget.vue";
import TimeScoreWidget from "@/redem/report/components/widgets/quality-score-widgets/ts-widget.vue";
import LoadingCircle from "@/shared/components/model/loading-circle.vue";

export default {
  props: {
    visible: Boolean,
    closeable: Boolean,
    datafileId: String
  },
  components: {
    TimeScoreWidget,
    ItemBatteryScoreWidget,
    OpenEndedScoreWidget,
    PredictionScoreWidget,
    CertinityScoreWidget,
    InformationScoreWidget,
    SocialDesirabilityScoreWidget,
    LoadingCircle
  },
  data() {
    return {
      /***** Operational Variable *****/
      algorithms: null,
      fullWidth: "col-lg-12",
      halfWidth: "col-lg-6",
      quaterWidth: "col-lg-4",
      isDisplayTS: false,
      isDisplayIBS: false,
      isDisplayOES: false,
      isDisplayPS: false,
      isDisplayCS: false,
      isDisplayIS: false,
      isDisplaySDS: false,
      errorTS: false,
      errorIBS: false,
      errorOES: false,
      errorPS: false,
      errorCS: false,
      errorIS: false,
      errorSDS: false
    };
  },
  computed: {
    showModal: {
      get() {
        return this.visible;
      },
      set(value) {
        if (!value) {
          this.$emit("close");
        }
      }
    }
  },
  async created() {
    this.algorithms = await DatafileService.getAlgorithmsInDatafile(
      this.datafileId
    );
  },
  methods: {
    clickClose() {
      this.$emit("close");
    },
    finishLoadingTS() {
      this.isDisplayTS = true;
    },
    finishLoadingIBS() {
      this.isDisplayIBS = true;
    },
    finishLoadingOES() {
      this.isDisplayOES = true;
    },
    finishLoadingPS() {
      this.isDisplayPS = true;
    },
    finishLoadingCS() {
      this.isDisplayCS = true;
    },
    finishLoadingIS() {
      this.isDisplayIS = true;
    },
    finishLoadingSDS() {
      this.isDisplaySDS = true;
    }
  }
};
</script>

<template>
  <b-modal
    v-model="showModal"
    centered
    hide-footer
    no-close-on-backdrop
    size="xl"
    scrollable
    :title="this.$t('datafileReport.qualityScoresModal.title')"
    @hide="clickClose()"
  >
    <!-- Description -->
    <div class="row mb-3">
      <div class="col-md-12">
        {{ $t("datafileReport.qualityScoresModal.description") }}
      </div>
    </div>

    <!-- Quality Scores -->
    <div class="row" v-if="this.algorithms">
      <!-- Open Ended Score Score -->
      <div v-if="algorithms.OES" class="col-md-4">
        <OpenEndedScoreWidget
          :datafileId="datafileId"
          @finishLoading="finishLoadingOES"
        />
        <LoadingCircle
          v-if="algorithms.OES && !this.isDisplayOES && !this.errorOES"
        />
        <APIError v-if="this.errorOES" />
      </div>
      <!-- Time Score -->
      <div v-if="algorithms.TS" class="col-md-4">
        <TimeScoreWidget
          :datafileId="datafileId"
          @finishLoading="finishLoadingTS"
        />
        <LoadingCircle
          v-if="algorithms.TS && !this.isDisplayTS && !this.errorTS"
        />
        <APIError v-if="this.errorTS" />
      </div>
      <!-- Item Battery Score -->
      <div v-if="algorithms.IBS" class="col-md-4">
        <ItemBatteryScoreWidget
          :datafileId="datafileId"
          @finishLoading="finishLoadingIBS"
        />
        <LoadingCircle
          v-if="algorithms.IBS && !this.isDisplayIBS && !this.errorIBS"
        />
        <APIError v-if="this.errorIBS" />
      </div>
      <!-- Prediction Score -->
      <div v-if="algorithms.PS" class="col-md-4">
        <PredictionScoreWidget
          :datafileId="datafileId"
          @finishLoading="finishLoadingPS"
        />
        <LoadingCircle
          v-if="algorithms.PS && !this.isDisplayPS && !this.errorPS"
        />
        <APIError v-if="this.errorPS" />
      </div>
      <!-- Certinity Score -->
      <div v-if="algorithms.CS" class="col-md-4">
        <CertinityScoreWidget
          :datafileId="datafileId"
          @finishLoading="finishLoadingCS"
        />
        <LoadingCircle
          v-if="algorithms.CS && !this.isDisplayCS && !this.errorCS"
        />
        <APIError v-if="this.errorCS" />
      </div>
      <!-- Infomation Score -->
      <div v-if="algorithms.IS" class="col-md-4">
        <InformationScoreWidget
          :datafileId="datafileId"
          @finishLoading="finishLoadingIS"
        />
        <LoadingCircle
          v-if="algorithms.IS && !this.isDisplayIS && !this.errorIS"
        />
        <APIError v-if="this.errorIS" />
      </div>
      <!-- Social Desirability Score -->
      <div v-if="algorithms.SDS" class="col-md-4">
        <SocialDesirabilityScoreWidget
          :datafileId="datafileId"
          @finishLoading="finishLoadingSDS"
        />
        <LoadingCircle
          v-if="algorithms.SDS && !this.isDisplaySDS && !this.errorSDS"
        />
        <APIError v-if="this.errorSDS" />
      </div>
    </div>

    <!-- Loading -->
    <div class="min-height col-12 text-center p-5" v-if="!this.algorithms">
      <b-spinner
        style="width: 5rem; height: 5rem"
        class="m-2"
        variant="mid"
        role="status"
      ></b-spinner>
    </div>
  </b-modal>
</template>
<style scoped>
.min-height {
  min-height: 350px;
}
</style>
