<script>
// Services
import { EventBus } from "@/shared/util/event-bus.js";
import { GLOBALS } from "@/shared/util/globals.js";
import { ERROR_CODES } from "@/shared/util/errorCodes.js";
import DatafileReportService from "@/api/services/datafileReport.service.js";

export default {
  props: {
    datafileId: String,
  },
  components: {},
  data() {
    return {
      cardHeight: "350px",
      targetQuotaSettingsPercentage: [],
      /********** Operational Variables ********/
      quotaSettingsNotSaved: false,
      isLoading: true
    };
  },
  async created() {
    await this.getReportTargetQuotaPercentage(this.datafileId);
  },
  mounted() {
    window.addEventListener("resize", this.setCardHeight);
  },
  unmounted() {
    window.removeEventListener("resize", this.setCardHeight);
  },
  methods: {
    clickInfQuotaWidget() {
      this.$emit("showQuotaInfo");
    },
    clickQuotaSettingsModal() {
      EventBus.$emit("showQuotaSettingsModal");
    },
    setCardHeight() {
      if (window.screen.width > 1000) {
        this.cardHeight = "350px";
      }

      if (window.screen.width < 999) {
        this.cardHeight = null;
      }
    },
    async getReportTargetQuotaPercentage(datafileID) {
      const response = await DatafileReportService.getReportTargetQuotaPercentage(
        datafileID,
        GLOBALS.reportPassword
      );
      this.isLoading = false;
      if (response === ERROR_CODES.QUOTA_SETTINGS_NOT_SAVED) {
        this.$emit("hideQuotaWidget");
      } else {
        this.targetQuotaSettingsPercentage = response;
        this.$emit("setMetadataWidgetWidth", "col-lg-5")
      }
    },
  }
};
</script>
<template>
  <div class="card" :style="{ height: this.cardHeight }">
    <div class="card-body">
      <!-- Header -->
      <h5 class="card-title">
        {{ $t("datafileReport.quotaSettingWidget.title") }}
      </h5>

      <!-- Loading untile data get set --->
      <div class="row justify-content-center" v-if="this.isLoading">
        <div class="col-6 text-center pt-5">
          <b-spinner
            style="width: 5rem; height: 5rem"
            class="m-2"
            variant="mid"
            role="status"
          ></b-spinner>
        </div>
      </div>

        <!-- Settings Available -->
        <div
          class="card-text mt-5"
          v-if="
            !this.quotaSettingsNotSaved && this.targetQuotaSettingsPercentage
          "
        >
          {{ $t("datafileReport.quotaSettingWidget.description") }}
          <div class="mt-3">
            <b-progress
              class="mt-4"
              :max="100"
              height="30px"
              style="font-size: 15px"
            >
              <b-progress-bar
                :value="
                  (this.targetQuotaSettingsPercentage[0] /
                    this.targetQuotaSettingsPercentage[1]) *
                    100
                "
                :label="
                  `${this.targetQuotaSettingsPercentage[0]} / ${this.targetQuotaSettingsPercentage[1]}`
                "
                variant="success"
                show-value
              ></b-progress-bar>
            </b-progress>
          </div>
        </div>
      </div>
  </div>
</template>
