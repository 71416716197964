<script>
import DatafileReportService from "@/api/services/datafileReport.service.js";
import { MeanChart } from "@/redem/datafiles/result-charts.js";
import { GLOBALS } from "@/shared/util/globals.js";

export default {
  props: {
    datafileId: String,
    isDisplayQualityScoreWidgets: Boolean
  },
  components: {},
  data() {
    return {
      meanChartOptions: null,
      meanRScoreRaw: 50,
      meanRScoreClean: 85
      /******** Operational Variables ******/
    };
  },
  async created() {
    this.meanChartOptions = new MeanChart();
    await this.fetchChartData();
  },
  methods: {
    showQualityScoreModal() {
      this.$emit("displayplayQualityScoreModal");
    },
    async fetchChartData() {
      const rScores = await DatafileReportService.getReportRScore(
        this.datafileId,
        GLOBALS.reportPassword
      );
      this.meanRScoreRaw = rScores[0];
      this.meanRScoreClean = rScores[1];
    }
  }
};
</script>

<template>
  <div class="card" style="height: 450px;">
    <div class="card-body">
      <div class="card-title mb-3">
        {{ $t("datafileReport.rScoreWidget.title") }}
        <div class="float-right">
          <b-button
            v-if="isDisplayQualityScoreWidgets"
            size="sm"
            variant="light"
            @click="showQualityScoreModal()"
          >
            {{ $t("datafileReport.rScoreWidget.readMore") }}
            <i class="bx bx-right-arrow-alt"></i>
          </b-button>
        </div>
      </div>
      <div class="card-text">
        {{ $t("datafileReport.rScoreWidget.description") }}
      </div>
      <!-- Chart Tabs -->
      <div class="card-text mt-4">
        <div class="row">
          <div class="col-12">
            <b-tabs>
              <!-- Clean Tab -->
              <b-tab active lazy>
                <template v-slot:title>
                  {{ $t("datafileReport.cleaned") }}
                </template>
                <div>
                  <apexchart
                    ref="meanChart"
                    class="apex-charts apexChartData mt-3"
                    type="radialBar"
                    height="250"
                    dir="ltr"
                    :series="[Math.round(this.meanRScoreClean)]"
                    :options="meanChartOptions.chartOptions"
                  ></apexchart>
                </div>
              </b-tab>
              <!-- Raw Tab -->
              <b-tab lazy>
                <template v-slot:title>
                  {{ $t("datafileReport.raw") }}
                </template>
                <div>
                  <apexchart
                    ref="meanChart"
                    class="apex-charts apexChartData mt-3"
                    type="radialBar"
                    height="250"
                    dir="ltr"
                    :series="[Math.round(this.meanRScoreRaw)]"
                    :options="meanChartOptions.chartOptions"
                  ></apexchart>
                </div>
              </b-tab>
            </b-tabs>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
