var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "card", staticStyle: { height: "450px" } }, [
    _c("div", { staticClass: "card-body" }, [
      _c("div", { staticClass: "card-title mb-3" }, [
        _vm._v(
          " " + _vm._s(_vm.$t("datafileReport.distributionWidget.title")) + " "
        ),
      ]),
      _c("div", { staticClass: "card-text" }, [
        _vm._v(
          " " +
            _vm._s(_vm.$t("datafileReport.distributionWidget.description")) +
            " "
        ),
      ]),
      _c("div", { staticClass: "card-text mt-4" }, [
        _c("div", { staticClass: "row" }, [
          _c(
            "div",
            { staticClass: "col-12" },
            [
              _c(
                "b-tabs",
                [
                  _c(
                    "b-tab",
                    {
                      attrs: { active: "", lazy: "" },
                      scopedSlots: _vm._u([
                        {
                          key: "title",
                          fn: function () {
                            return [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.$t("datafileReport.cleaned")) +
                                  " "
                              ),
                            ]
                          },
                          proxy: true,
                        },
                      ]),
                    },
                    [
                      _c(
                        "div",
                        [
                          _c("apexchart", {
                            ref: "distributionChart",
                            staticClass: "apex-charts mt-3",
                            attrs: {
                              dir: "ltr",
                              type: "bar",
                              height: "250",
                              series: [
                                _vm.chartDataForCleanedDistributionChart,
                              ],
                              options:
                                _vm.distributionChartOptions.chartOptions,
                            },
                          }),
                        ],
                        1
                      ),
                    ]
                  ),
                  _c(
                    "b-tab",
                    {
                      attrs: { lazy: "" },
                      scopedSlots: _vm._u([
                        {
                          key: "title",
                          fn: function () {
                            return [
                              _vm._v(
                                " " + _vm._s(_vm.$t("datafileReport.raw")) + " "
                              ),
                            ]
                          },
                          proxy: true,
                        },
                      ]),
                    },
                    [
                      _c(
                        "div",
                        [
                          _c("apexchart", {
                            ref: "distributionChart",
                            staticClass: "apex-charts mt-3",
                            attrs: {
                              dir: "ltr",
                              type: "bar",
                              height: "250",
                              series: [_vm.chartDataForRawDistributionChart],
                              options:
                                _vm.distributionChartOptions.chartOptions,
                            },
                          }),
                        ],
                        1
                      ),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }