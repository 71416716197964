var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-modal",
    {
      attrs: {
        centered: "",
        "hide-footer": "",
        "hide-header": "",
        "no-close-on-backdrop": "",
      },
      model: {
        value: _vm.showModal,
        callback: function ($$v) {
          _vm.showModal = $$v
        },
        expression: "showModal",
      },
    },
    [
      _c("div", { staticClass: "row text-center p-4" }, [
        _c("div", { staticClass: "col-md-12" }, [
          _c("img", {
            staticClass: "img-fluid",
            staticStyle: { width: "250px" },
            attrs: {
              src: require("@/assets/images/report-password-image.png"),
              alt: "",
            },
          }),
          _c("h4", { staticClass: "mt-3" }, [
            _vm._v(_vm._s(_vm.$t("datafileReport.passwordModal.title"))),
          ]),
        ]),
        _c(
          "div",
          { staticClass: "col-md-12" },
          [
            _c(
              "b-form",
              {
                staticClass: "p-3",
                attrs: { id: "passForm" },
                on: {
                  submit: function ($event) {
                    $event.preventDefault()
                    return _vm.submitPassword.apply(null, arguments)
                  },
                },
              },
              [
                _c(
                  "div",
                  {},
                  [
                    _c("b-form-input", {
                      class: {
                        "is-invalid": _vm.passwordIncorrect,
                      },
                      attrs: {
                        type: "password",
                        placeholder: this.$t(
                          "datafileReport.passwordModal.placeholder"
                        ),
                      },
                      model: {
                        value: _vm.password,
                        callback: function ($$v) {
                          _vm.password = $$v
                        },
                        expression: "password",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "mt-4" },
                  [
                    _c(
                      "b-button",
                      { attrs: { type: "submit", variant: "success" } },
                      [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t("datafileReport.passwordModal.button")
                            ) +
                            " "
                        ),
                      ]
                    ),
                  ],
                  1
                ),
              ]
            ),
          ],
          1
        ),
        _c("div", { staticClass: "col-md-12 mt-3 text-center" }, [
          _c("p", [
            _vm._v("© " + _vm._s(new Date().getFullYear()) + " Redem GmbH"),
          ]),
          _c("div", { staticClass: "text-center" }, [
            _c(
              "span",
              {
                staticClass: "mr-2",
                staticStyle: { cursor: "pointer" },
                on: {
                  click: function ($event) {
                    return _vm.setLanguage(_vm.languages[0].language)
                  },
                },
              },
              [
                _c("img", {
                  staticClass: "mr-1",
                  attrs: { src: _vm.languages[0].flag, height: "10" },
                }),
                _vm._v(" " + _vm._s(_vm.languages[0].title) + " "),
              ]
            ),
            _vm._v(" • "),
            _c(
              "span",
              {
                staticClass: "ml-2",
                staticStyle: { cursor: "pointer" },
                on: {
                  click: function ($event) {
                    return _vm.setLanguage(_vm.languages[1].language)
                  },
                },
              },
              [
                _c("img", {
                  staticClass: "mr-1",
                  attrs: { src: _vm.languages[1].flag, height: "10" },
                }),
                _vm._v(" " + _vm._s(_vm.languages[1].title) + " "),
              ]
            ),
          ]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }