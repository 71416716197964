<script>
import { MeanChart } from "@/redem/datafiles/result-charts.js";
import DatafileReportService from "@/api/services/datafileReport.service.js";
import { GLOBALS } from "@/shared/util/globals.js";

export default {
  props: {
    datafileId: String
  },
  data() {
    return {
      meanChartOptions: null,
      chartDataForCleanedSocialDesirabilityScoreMeanChart: 0,
      chartDataForRawSocialDesirabilityScoreMeanChart: 0
    };
  },
  async created() {
    await this.fetchChartData();
    this.meanChartOptions = new MeanChart();
  },
  methods: {
    async fetchChartData() {
      const sdScores = await DatafileReportService.getReportAverageSocialDesirabilityScore(
        this.datafileId,
        GLOBALS.reportPassword
      );
      this.$emit("finishLoading");
      this.chartDataForRawSocialDesirabilityScoreMeanChart = sdScores[0];
      this.chartDataForCleanedSocialDesirabilityScoreMeanChart = sdScores[1];
    }
  }
};
</script>

<template>
  <div class="card" style="border: 1px solid #f5f5f5">
    <div class="card-body">
      <div class="card-title">
        {{ $t("qualityScoreWidgets.socialDesirabilityScoreWidget.title") }}
      </div>
      <div class="card-text" style="height: 120px;">
        {{
          $t("qualityScoreWidgets.socialDesirabilityScoreWidget.description")
        }}
      </div>
      <div class="card-text">
        <div class="row mt-4">
          <div class="col-md-12 text-center">
            <b-tabs>
              <b-tab active lazy>
                <template v-slot:title>
                  {{ $t("datafileReport.cleaned") }}
                </template>
                <div>
                  <apexchart
                    ref="PSMeanChart"
                    class="apex-charts apexChartData"
                    type="radialBar"
                    height="250"
                    dir="ltr"
                    :series="[
                      chartDataForCleanedSocialDesirabilityScoreMeanChart
                    ]"
                    :options="meanChartOptions.chartOptions"
                  ></apexchart>
                </div>
              </b-tab>
              <b-tab lazy>
                <template v-slot:title>
                  {{ $t("datafileReport.raw") }}
                </template>
                <div>
                  <apexchart
                    ref="PSMeanChart"
                    class="apex-charts apexChartData"
                    type="radialBar"
                    height="250"
                    dir="ltr"
                    :series="[chartDataForRawSocialDesirabilityScoreMeanChart]"
                    :options="meanChartOptions.chartOptions"
                  ></apexchart>
                </div>
              </b-tab>
            </b-tabs>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
