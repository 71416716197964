var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      !this.isMobile
        ? _c("div", { staticClass: "card", style: { height: "350px" } }, [
            _c("div", { staticClass: "card-body" }, [
              _c("div", { staticClass: "row card-text" }, [
                _c("div", { staticClass: "col-md-9" }, [
                  _c("h5", [_vm._v(_vm._s(_vm.datafileTitle))]),
                ]),
                _c(
                  "div",
                  { staticClass: "col-md-3 text-right" },
                  [
                    _vm.weighted
                      ? _c(
                          "b-badge",
                          {
                            staticClass: "mr-2",
                            staticStyle: { "font-size": "12px" },
                            attrs: { variant: "primary" },
                          },
                          [
                            _c("i", { staticClass: "bx bx-slider mr-1" }),
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t(
                                    "datafileResultCleanedTab.metadataWidget.weightedTag"
                                  )
                                ) +
                                " "
                            ),
                          ]
                        )
                      : _vm._e(),
                    _vm.liveStatus
                      ? _c(
                          "div",
                          [
                            _vm.isLiveStatusRunning
                              ? _c(
                                  "b-badge",
                                  {
                                    staticStyle: { "font-size": "12px" },
                                    attrs: { variant: "light" },
                                  },
                                  [
                                    _c("i", {
                                      staticClass:
                                        "bx bxs-circle bx-flashing text-success",
                                    }),
                                    _c(
                                      "span",
                                      { staticClass: "text-success" },
                                      [_vm._v(" LIVE ")]
                                    ),
                                  ]
                                )
                              : _vm._e(),
                            !_vm.isLiveStatusRunning
                              ? _c(
                                  "b-badge",
                                  {
                                    staticClass: "ml-2",
                                    staticStyle: { "font-size": "12px" },
                                    attrs: { variant: "light" },
                                  },
                                  [
                                    _c("i", {
                                      staticClass: "bx bxs-flag-checkered",
                                    }),
                                    _c("span", {}, [_vm._v(" FINISHED ")]),
                                  ]
                                )
                              : _vm._e(),
                          ],
                          1
                        )
                      : _vm._e(),
                  ],
                  1
                ),
              ]),
              _c("div", { staticClass: "card-text mt-4" }, [
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col-md-6 mb-3" }, [
                    _vm._v(
                      " " + _vm._s(_vm.$t("datafileReport.uploadedBy")) + " "
                    ),
                    _c("div", { staticClass: "mt-2" }, [
                      _vm.companyLogo
                        ? _c("img", {
                            staticStyle: { height: "40px" },
                            attrs: { src: _vm.companyLogo },
                          })
                        : _c("img", {
                            staticStyle: { width: "30px", height: "30px" },
                            attrs: {
                              src: "https://redem-resources.s3.eu-central-1.amazonaws.com/default-images/dafault-company.jpg",
                            },
                          }),
                      _c("br"),
                      _vm._v(" " + _vm._s(_vm.companyName) + " "),
                    ]),
                  ]),
                  _vm.clientName
                    ? _c("div", { staticClass: "col-md-6" }, [
                        _vm._v(
                          " " +
                            _vm._s(_vm.$t("datafileReport.createdFor")) +
                            " "
                        ),
                        _c("div", { staticClass: "mt-2" }, [
                          _vm.clientLogo
                            ? _c("img", {
                                staticStyle: { height: "40px" },
                                attrs: { src: _vm.clientLogo },
                              })
                            : _c("img", {
                                staticStyle: { width: "30px", height: "30px" },
                                attrs: {
                                  src: "https://redem-resources.s3.eu-central-1.amazonaws.com/default-images/dafault-company.jpg",
                                },
                              }),
                          _c("br"),
                          _vm._v(" " + _vm._s(_vm.clientName) + " "),
                        ]),
                      ])
                    : _vm._e(),
                ]),
              ]),
              _c("hr"),
              _c("div", { staticClass: "card-text mt-3" }, [
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col-md-12 col-lg-7" }, [
                    _c("div", { staticClass: "row" }, [
                      _c("div", { staticClass: "col-md-6 mb-3" }, [
                        _c("div", [
                          _vm._v(
                            _vm._s(_vm.$t("datafileReport.includedDatarecords"))
                          ),
                        ]),
                        _c("div", [
                          _c("strong", [_vm._v(_vm._s(_vm.includedRecords))]),
                        ]),
                      ]),
                      _c("div", { staticClass: "col-md-6 mb-3" }, [
                        _c("div", [
                          _vm._v(
                            _vm._s(_vm.$t("datafileReport.removedDatarecords"))
                          ),
                        ]),
                        _c("div", [
                          _c("strong", [
                            _vm._v(" " + _vm._s(_vm.removedRecords)),
                          ]),
                        ]),
                      ]),
                      _c("div", { staticClass: "col-md-6 mb-3" }, [
                        _c("div", [
                          _vm._v(
                            _vm._s(_vm.$t("datafileReport.importMethod.label"))
                          ),
                        ]),
                        _c("div", [
                          _vm.uploadMethod === "API Import"
                            ? _c("strong", [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$t("datafileReport.importMethod.api")
                                    ) +
                                    " "
                                ),
                              ])
                            : _vm._e(),
                          _vm.uploadMethod === "Manual Upload"
                            ? _c("strong", [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$t(
                                        "datafileReport.importMethod.manual"
                                      )
                                    ) +
                                    " "
                                ),
                              ])
                            : _vm._e(),
                        ]),
                      ]),
                      _c("div", { staticClass: "col-md-6 mb-3" }, [
                        _c("div", [
                          _vm._v(_vm._s(_vm.$t("datafileReport.creationDate"))),
                        ]),
                        _c("div", [
                          _c("strong", [
                            _vm._v(_vm._s(_vm.createdDate.slice(0, 10))),
                          ]),
                        ]),
                      ]),
                    ]),
                  ]),
                  _c("div", { staticClass: "col-md-12 col-lg-5 mb-3" }, [
                    _c("div", [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t("datafileReport.scoreThreshold.label")
                          ) +
                          " "
                      ),
                      _c("i", {
                        staticClass:
                          "bx bxs-info-circle ml-1 font-size-14 text-info bx-tada",
                        staticStyle: { cursor: "pointer" },
                        on: {
                          click: function ($event) {
                            return _vm.clickInfoScreThreshold()
                          },
                        },
                      }),
                    ]),
                    Object.keys(_vm.scoreThresholds).length > 0
                      ? _c(
                          "div",
                          _vm._l(
                            Object.keys(_vm.scoreThresholds),
                            function (scoreType) {
                              return _c("div", { key: scoreType }, [
                                _c("strong", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t(
                                        `datafileReport.scoreTypes.${scoreType}`
                                      )
                                    ) +
                                      ": " +
                                      _vm._s(_vm.scoreThresholds[scoreType])
                                  ),
                                ]),
                              ])
                            }
                          ),
                          0
                        )
                      : _vm._e(),
                    Object.keys(_vm.scoreThresholds).length === 0
                      ? _c("div", [
                          _c("strong", [
                            _vm._v(
                              _vm._s(
                                _vm.$t(
                                  "datafileReport.scoreThreshold.noCleaningApply"
                                )
                              )
                            ),
                          ]),
                        ])
                      : _vm._e(),
                  ]),
                ]),
              ]),
            ]),
          ])
        : _vm._e(),
      this.isMobile
        ? _c("div", { staticClass: "card" }, [
            _c("div", { staticClass: "card-body" }, [
              _c("div", { staticClass: "row card-text" }, [
                _c("div", { staticClass: "col-md-12" }, [
                  _c("h5", [_vm._v(_vm._s(_vm.datafileTitle))]),
                ]),
                _c(
                  "div",
                  { staticClass: "col-md-12" },
                  [
                    _vm.weighted
                      ? _c(
                          "b-badge",
                          {
                            staticClass: "mr-2",
                            staticStyle: { "font-size": "12px" },
                            attrs: { variant: "primary" },
                          },
                          [
                            _c("i", { staticClass: "bx bx-slider mr-1" }),
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t(
                                    "datafileResultCleanedTab.metadataWidget.weightedTag"
                                  )
                                ) +
                                " "
                            ),
                          ]
                        )
                      : _vm._e(),
                    _vm.liveStatus
                      ? _c(
                          "div",
                          [
                            _vm.isLiveStatusRunning
                              ? _c(
                                  "b-badge",
                                  {
                                    staticStyle: { "font-size": "12px" },
                                    attrs: { variant: "light" },
                                  },
                                  [
                                    _c("i", {
                                      staticClass:
                                        "bx bxs-circle bx-flashing text-success",
                                    }),
                                    _c(
                                      "span",
                                      { staticClass: "text-success" },
                                      [_vm._v(" LIVE ")]
                                    ),
                                  ]
                                )
                              : _vm._e(),
                            !_vm.isLiveStatusRunning
                              ? _c(
                                  "b-badge",
                                  {
                                    staticClass: "ml-2",
                                    staticStyle: { "font-size": "12px" },
                                    attrs: { variant: "light" },
                                  },
                                  [
                                    _c("i", {
                                      staticClass: "bx bxs-flag-checkered",
                                    }),
                                    _c("span", {}, [_vm._v(" FINISHED ")]),
                                  ]
                                )
                              : _vm._e(),
                          ],
                          1
                        )
                      : _vm._e(),
                  ],
                  1
                ),
              ]),
              _c("div", { staticClass: "card-text mt-4" }, [
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col-md-6 mb-3" }, [
                    _vm._v(
                      " " + _vm._s(_vm.$t("datafileReport.uploadedBy")) + " "
                    ),
                    _c("div", { staticClass: "mt-2" }, [
                      _vm.companyLogo
                        ? _c("img", {
                            staticStyle: { height: "40px" },
                            attrs: { src: _vm.companyLogo },
                          })
                        : _c("img", {
                            staticStyle: { width: "30px", height: "30px" },
                            attrs: {
                              src: "https://redem-resources.s3.eu-central-1.amazonaws.com/default-images/dafault-company.jpg",
                            },
                          }),
                      _c("br"),
                      _vm._v(" " + _vm._s(_vm.companyName) + " "),
                    ]),
                  ]),
                  _vm.clientName
                    ? _c("div", { staticClass: "col-md-6" }, [
                        _vm._v(
                          " " +
                            _vm._s(_vm.$t("datafileReport.createdFor")) +
                            " "
                        ),
                        _c("div", { staticClass: "mt-2" }, [
                          _vm.clientLogo
                            ? _c("img", {
                                staticStyle: { height: "40px" },
                                attrs: { src: _vm.clientLogo },
                              })
                            : _c("img", {
                                staticStyle: { width: "30px", height: "30px" },
                                attrs: {
                                  src: "https://redem-resources.s3.eu-central-1.amazonaws.com/default-images/dafault-company.jpg",
                                },
                              }),
                          _c("br"),
                          _vm._v(" " + _vm._s(_vm.clientName) + " "),
                        ]),
                      ])
                    : _vm._e(),
                ]),
              ]),
              _c("hr"),
              _c("div", { staticClass: "card-text mt-3" }, [
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col-md-12 col-lg-7" }, [
                    _c("div", { staticClass: "row" }, [
                      _c("div", { staticClass: "col-md-6 mb-3" }, [
                        _c("div", [
                          _vm._v(
                            _vm._s(_vm.$t("datafileReport.includedDatarecords"))
                          ),
                        ]),
                        _c("div", [
                          _c("strong", [_vm._v(_vm._s(_vm.includedRecords))]),
                        ]),
                      ]),
                      _c("div", { staticClass: "col-md-6 mb-3" }, [
                        _c("div", [
                          _vm._v(
                            _vm._s(_vm.$t("datafileReport.removedDatarecords"))
                          ),
                        ]),
                        _c("div", [
                          _c("strong", [
                            _vm._v(" " + _vm._s(_vm.removedRecords)),
                          ]),
                        ]),
                      ]),
                      _c("div", { staticClass: "col-md-6 mb-3" }, [
                        _c("div", [
                          _vm._v(
                            _vm._s(_vm.$t("datafileReport.importMethod.label"))
                          ),
                        ]),
                        _c("div", [
                          _vm.uploadMethod === "API Import"
                            ? _c("strong", [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$t("datafileReport.importMethod.api")
                                    ) +
                                    " "
                                ),
                              ])
                            : _vm._e(),
                          _vm.uploadMethod === "Manual Upload"
                            ? _c("strong", [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$t(
                                        "datafileReport.importMethod.manual"
                                      )
                                    ) +
                                    " "
                                ),
                              ])
                            : _vm._e(),
                        ]),
                      ]),
                      _c("div", { staticClass: "col-md-6 mb-3" }, [
                        _c("div", [
                          _vm._v(_vm._s(_vm.$t("datafileReport.creationDate"))),
                        ]),
                        _c("div", [
                          _c("strong", [
                            _vm._v(_vm._s(_vm.createdDate.slice(0, 10))),
                          ]),
                        ]),
                      ]),
                    ]),
                  ]),
                  _c("div", { staticClass: "col-md-12 col-lg-5 mb-3" }, [
                    _c("div", [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t("datafileReport.scoreThreshold.label")
                          ) +
                          " "
                      ),
                      _c("i", {
                        staticClass:
                          "bx bxs-info-circle ml-1 font-size-14 text-info bx-tada",
                        staticStyle: { cursor: "pointer" },
                        on: {
                          click: function ($event) {
                            return _vm.clickInfoScreThreshold()
                          },
                        },
                      }),
                    ]),
                    Object.keys(_vm.scoreThresholds).length > 0
                      ? _c(
                          "div",
                          _vm._l(
                            Object.keys(_vm.scoreThresholds),
                            function (scoreType) {
                              return _c("div", { key: scoreType }, [
                                _c("strong", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t(
                                        `datafileReport.scoreTypes.${scoreType}`
                                      )
                                    ) +
                                      ": " +
                                      _vm._s(_vm.scoreThresholds[scoreType])
                                  ),
                                ]),
                              ])
                            }
                          ),
                          0
                        )
                      : _vm._e(),
                    Object.keys(_vm.scoreThresholds).length === 0
                      ? _c("div", [
                          _c("strong", [
                            _vm._v(
                              _vm._s(
                                _vm.$t(
                                  "datafileReport.scoreThreshold.noCleaningApply"
                                )
                              )
                            ),
                          ]),
                        ])
                      : _vm._e(),
                  ]),
                ]),
              ]),
            ]),
          ])
        : _vm._e(),
      _vm.displayScoreThresholdInfoModal
        ? _c("ScoreThresholdInfoModal", {
            attrs: {
              visible: _vm.displayScoreThresholdInfoModal,
              scoreThresholds: _vm.scoreThresholds,
            },
            on: {
              close: function ($event) {
                _vm.displayScoreThresholdInfoModal = false
              },
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }