var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-modal",
    {
      attrs: { centered: "", "hide-footer": "", "hide-header": "" },
      model: {
        value: _vm.showModal,
        callback: function ($$v) {
          _vm.showModal = $$v
        },
        expression: "showModal",
      },
    },
    [
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-md-12 mb-3" }, [
          _c("div", { staticClass: "float-right" }, [
            _c("i", {
              staticClass: "bx bx-x font-size-20",
              staticStyle: { cursor: "pointer" },
              on: {
                click: function ($event) {
                  return _vm.closeMode()
                },
              },
            }),
          ]),
          _c("h5", {}, [
            _vm._v(
              " " +
                _vm._s(_vm.$t("datafileReport.scoreThresholdInfoModal.title")) +
                " "
            ),
          ]),
        ]),
        _c("div", { staticClass: "col-md-12" }, [
          _vm._v(
            " " +
              _vm._s(
                _vm.$t("datafileReport.scoreThresholdInfoModal.description")
              ) +
              " "
          ),
        ]),
        _c(
          "div",
          { staticClass: "col-md-12 mt-3" },
          _vm._l(Object.keys(_vm.scoreThresholds), function (scoreType) {
            return _c("div", { key: scoreType, staticClass: "mb-2" }, [
              scoreType === "R"
                ? _c("div", [
                    _c("label", [_vm._v("Redem-Score ")]),
                    _vm._v(
                      _vm._s(
                        _vm.$t("datafileReport.scoreThresholdInfoModal.rScore")
                      ) + " "
                    ),
                  ])
                : _vm._e(),
              scoreType === "TS"
                ? _c("div", [
                    _c("label", [_vm._v("Time-Score ")]),
                    _vm._v(
                      _vm._s(
                        _vm.$t(
                          "datafileReport.scoreThresholdInfoModal.timeScore"
                        )
                      ) + " "
                    ),
                  ])
                : _vm._e(),
              scoreType === "OES"
                ? _c("div", [
                    _c("label", [_vm._v("Open-Ended-Score ")]),
                    _vm._v(
                      _vm._s(
                        _vm.$t(
                          "datafileReport.scoreThresholdInfoModal.openEndedScore"
                        )
                      ) + " "
                    ),
                  ])
                : _vm._e(),
              scoreType === "PS"
                ? _c("div", [
                    _c("label", [_vm._v("Prediction-Score ")]),
                    _vm._v(
                      _vm._s(
                        _vm.$t(
                          "datafileReport.scoreThresholdInfoModal.predictionScore"
                        )
                      ) + " "
                    ),
                  ])
                : _vm._e(),
              scoreType === "CS"
                ? _c("div", [
                    _c("label", [_vm._v("Certainty-Score ")]),
                    _vm._v(
                      _vm._s(
                        _vm.$t(
                          "datafileReport.scoreThresholdInfoModal.certaintyScore"
                        )
                      ) + " "
                    ),
                  ])
                : _vm._e(),
              scoreType === "IBS"
                ? _c("div", [
                    _c("label", [_vm._v("Grid-Question-Score ")]),
                    _vm._v(
                      _vm._s(
                        _vm.$t(
                          "datafileReport.scoreThresholdInfoModal.itemBatteryScore"
                        )
                      ) + " "
                    ),
                  ])
                : _vm._e(),
            ])
          }),
          0
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }