var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "card", style: { height: this.cardHeight } },
    [
      _c("div", { staticClass: "card-body" }, [
        _c("h5", { staticClass: "card-title" }, [
          _vm._v(
            " " + _vm._s(_vm.$t("datafileReport.certificateWidget.title")) + " "
          ),
          _c("i", {
            staticClass:
              "bx bxs-info-circle ml-2 font-size-16 text-info bx-tada",
            staticStyle: { cursor: "pointer" },
            on: {
              click: function ($event) {
                return _vm.clickInfQualityLevels()
              },
            },
          }),
          _c(
            "a",
            { attrs: { href: _vm.certificateImageURL, download: "" } },
            [
              _c(
                "b-button",
                {
                  staticClass: "float-right",
                  attrs: { size: "sm", variant: "light" },
                },
                [_c("i", { staticClass: "bx bxs-download" })]
              ),
            ],
            1
          ),
        ]),
        _c("div", { staticClass: "badge badge-gold font-size-12" }, [
          _vm._v(" " + _vm._s(_vm.certificateLevel) + " "),
        ]),
        _c("div", { staticClass: "row mt-4" }, [
          _vm._m(0),
          _c("div", { staticClass: "col-12 mt-3 text-center" }, [
            _c("h5", [_vm._v(_vm._s(_vm.certificateCode))]),
          ]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-12 text-center" }, [
      _c("img", {
        staticClass: "img-fluid",
        staticStyle: { width: "150px" },
        attrs: {
          src: require("@/assets/images/redem-certification.png"),
          alt: "",
        },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }