<script>
import i18n from "@/i18n";

export default {
  props: {
    visible: Boolean,
    passwordIncorrect: Boolean
  },
  data() {
    return {
      languages: [
        {
          flag: require("@/assets/images/flags/us.jpg"),
          language: "en",
          title: "English"
        },
        {
          flag: require("@/assets/images/flags/germany.jpg"),
          language: "de",
          title: "Deutsch"
        }
      ],
      lan: i18n.locale,
      password: ""
    };
  },
  computed: {
    showModal: {
      get() {
        return this.visible;
      },
      set(value) {
        if (!value) {
          this.$emit("close");
        }
      }
    }
  },
  methods: {
    submitPassword() {
      this.$emit("submitPassword", this.password);
    },
    setLanguage(locale) {
      this.lan = locale;
      i18n.locale = locale;
      window.localStorage.setItem("locale", locale);
    }
  }
};
</script>

<template>
  <b-modal
    centered
    hide-footer
    hide-header
    no-close-on-backdrop
    v-model="showModal"
  >
    <div class="row text-center p-4">
      <!-- Title -->
      <div class="col-md-12">
        <img
          src="@/assets/images/report-password-image.png"
          alt
          class="img-fluid"
          style="width: 250px"
        />
        <h4 class="mt-3">{{ $t("datafileReport.passwordModal.title") }}</h4>
      </div>

      <!-- Form -->
      <div class="col-md-12">
        <b-form id="passForm" class="p-3" @submit.prevent="submitPassword">
          <div class="">
            <b-form-input
              type="password"
              :placeholder="this.$t('datafileReport.passwordModal.placeholder')"
              :class="{
                'is-invalid': passwordIncorrect
              }"
              v-model="password"
            ></b-form-input>
          </div>
          <div class="mt-4">
            <b-button type="submit" variant="success">
              {{ $t("datafileReport.passwordModal.button") }}
            </b-button>
          </div>
        </b-form>
      </div>

      <!-- Footer -->
      <div class="col-md-12 mt-3 text-center">
        <p>© {{ new Date().getFullYear() }} Redem GmbH</p>
        <!-- language Selector -->
        <div class="text-center">
          <span
            class="mr-2"
            @click="setLanguage(languages[0].language)"
            style="cursor: pointer"
          >
            <img class="mr-1" :src="languages[0].flag" height="10" />
            {{ languages[0].title }}
          </span>
          &bull;
          <span
            class="ml-2"
            @click="setLanguage(languages[1].language)"
            style="cursor: pointer"
          >
            <img class="mr-1" :src="languages[1].flag" height="10" />
            {{ languages[1].title }}
          </span>
        </div>
      </div>
    </div>
  </b-modal>
</template>
