var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("header", { attrs: { id: "page-topbar" } }, [
    _c("div", { staticClass: "row navbar-header" }, [
      _vm._m(0),
      _c(
        "div",
        { staticClass: "col-6 text-right" },
        [
          _c(
            "b-dropdown",
            {
              attrs: {
                variant: "white",
                right: "",
                "toggle-class": "header-item",
              },
              scopedSlots: _vm._u([
                {
                  key: "button-content",
                  fn: function () {
                    return [
                      _c("img", {
                        attrs: {
                          src: _vm.flag,
                          alt: "Header Language",
                          height: "16",
                        },
                      }),
                      _vm._v(" " + _vm._s(_vm.text) + " "),
                    ]
                  },
                  proxy: true,
                },
              ]),
            },
            _vm._l(_vm.languages, function (entry, i) {
              return _c(
                "b-dropdown-item",
                {
                  key: `Lang${i}`,
                  staticClass: "notify-item",
                  class: { active: _vm.lan === entry.language },
                  attrs: { value: entry },
                  on: {
                    click: function ($event) {
                      return _vm.setLanguage(
                        entry.language,
                        entry.title,
                        entry.flag
                      )
                    },
                  },
                },
                [
                  _c("img", {
                    staticClass: "me-1",
                    attrs: {
                      src: `${entry.flag}`,
                      alt: "user-image",
                      height: "12",
                    },
                  }),
                  _c("span", { staticClass: "align-middle" }, [
                    _vm._v(_vm._s(entry.title)),
                  ]),
                ]
              )
            }),
            1
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-6" }, [
      _c("div", { staticClass: "pl-4" }, [
        _c("img", {
          attrs: {
            src: require("@/assets/images/Redem-Logo.png"),
            alt: "",
            height: "30",
          },
        }),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }