var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "Layout",
    [
      !_vm.passwordLoading && (_vm.passwordConfirmed || !_vm.passwordRequired)
        ? _c(
            "div",
            [
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-md-12" }, [
                  _c(
                    "div",
                    {
                      staticClass:
                        "page-title-box d-flex align-items-center justify-content-between",
                    },
                    [
                      _c("h4", { staticClass: "mb-0 font-size-18" }, [
                        _vm._v(_vm._s(_vm.$t("datafileReport.title"))),
                      ]),
                    ]
                  ),
                ]),
              ]),
              _vm.liveStatus === _vm.liveCleaningStatus.running
                ? _c(
                    "b-alert",
                    {
                      attrs: { variant: "info" },
                      model: {
                        value: _vm.liveStatusAlert,
                        callback: function ($$v) {
                          _vm.liveStatusAlert = $$v
                        },
                        expression: "liveStatusAlert",
                      },
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t("datafileReport.liveSurveyRunningInfoText")
                          ) +
                          " "
                      ),
                    ]
                  )
                : _vm._e(),
              _vm.displayShortReport
                ? _c("div", { staticClass: "row justify-content-center" }, [
                    _c(
                      "div",
                      { staticClass: "col-lg-9" },
                      [
                        _c("MetadataWidget", {
                          attrs: { datafileId: this.datafileId },
                          on: {
                            setCertificateCode: _vm.setCertCode,
                            setCertificateLevel: _vm.setCertLevel,
                            setCertificateImageURL: _vm.setCertificateLevelURL,
                            setIsDisplayQualityScoreWidgets:
                              _vm.setIsDisplayQualityScoreWidgets,
                            setLiveStatus: _vm.setLiveStatus,
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-lg-3" },
                      [
                        _c("QualitySignWidget", {
                          attrs: {
                            datafileId: this.datafileId,
                            certificateCode: _vm.certificateCode,
                            certificateLevel: _vm.certificateLevel,
                            certificateImageURL: _vm.certificateImageURL,
                          },
                          on: {
                            displayCertificationLevelModal: function ($event) {
                              return _vm.showCertificationLevelModal()
                            },
                          },
                        }),
                      ],
                      1
                    ),
                  ])
                : _vm._e(),
              !_vm.displayShortReport
                ? _c("div", [
                    _c("div", { staticClass: "row" }, [
                      _c(
                        "div",
                        { class: this.metadataWidgetWidth },
                        [
                          _c("MetadataWidget", {
                            attrs: { datafileId: this.datafileId },
                            on: {
                              setCertificateCode: _vm.setCertCode,
                              setCertificateLevel: _vm.setCertLevel,
                              setCertificateImageURL:
                                _vm.setCertificateLevelURL,
                              setIsDisplayQualityScoreWidgets:
                                _vm.setIsDisplayQualityScoreWidgets,
                              setLiveStatus: _vm.setLiveStatus,
                            },
                          }),
                        ],
                        1
                      ),
                      _vm.isDisplayCertificateWidget
                        ? _c(
                            "div",
                            { staticClass: "col-md-4 col-lg-3" },
                            [
                              _c("QualitySignWidget", {
                                attrs: {
                                  datafileId: this.datafileId,
                                  certificateCode: _vm.certificateCode,
                                  certificateLevel: _vm.certificateLevel,
                                  certificateImageURL: _vm.certificateImageURL,
                                },
                                on: {
                                  displayCertificationLevelModal: function (
                                    $event
                                  ) {
                                    return _vm.showCertificationLevelModal()
                                  },
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.isDisplayQuotaWidget
                        ? _c(
                            "div",
                            { staticClass: "col-md-8 col-lg-4" },
                            [
                              _c("QuotaSettingsWidget", {
                                attrs: { datafileId: this.datafileId },
                                on: {
                                  showQuotaInfo: function ($event) {
                                    return _vm.showQuotaSettingsInfo(true)
                                  },
                                  hideQuotaWidget: function ($event) {
                                    _vm.isDisplayQuotaWidget = false
                                  },
                                  setMetadataWidgetWidth:
                                    _vm.setMetadataWidgetWidth,
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                    ]),
                    _c("div", { staticClass: "row" }, [
                      _c(
                        "div",
                        { staticClass: "col-md-12 col-lg-4" },
                        [
                          _c("RScoreWidget", {
                            attrs: {
                              datafileId: this.datafileId,
                              isDisplayQualityScoreWidgets:
                                _vm.isDisplayQualityScoreWidgets,
                            },
                            on: {
                              displayplayQualityScoreModal: function ($event) {
                                return _vm.showQualityScoreModal()
                              },
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "col-md-12 col-lg-4" },
                        [
                          _c("DistributionWidget", {
                            attrs: { datafileId: this.datafileId },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "col-md-12 col-lg-4" },
                        [
                          _c("TrafficLightWidget", {
                            attrs: { datafileId: this.datafileId },
                          }),
                        ],
                        1
                      ),
                    ]),
                    _vm.isDisplayQualityScoreWidgetsSet &&
                    _vm.isAlgorithmsInDatafileSet
                      ? _c("div", { staticClass: "row" }, [
                          _c(
                            "div",
                            { staticClass: "col-md-12" },
                            [
                              _c("DataRecordsWidget", {
                                attrs: {
                                  datafileId: this.datafileId,
                                  isDisplayQualityScoreWidgets:
                                    _vm.isDisplayQualityScoreWidgets,
                                  algorithmsInDatafile:
                                    _vm.algorithmsInDatafile,
                                },
                              }),
                            ],
                            1
                          ),
                        ])
                      : _vm._e(),
                  ])
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
      !_vm.passwordLoading && _vm.passwordRequired && !_vm.passwordConfirmed
        ? _c(
            "div",
            [
              _c("PasswordModal", {
                attrs: {
                  visible: _vm.passwordRequired === true,
                  passwordIncorrect: _vm.passwordIncorrect,
                },
                on: { submitPassword: _vm.checkPassword },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm.passwordLoading ? _c("LoadingCircle") : _vm._e(),
      _vm.showQuotaSettingsInfoModel
        ? _c("QuotaSettingsInfoModal", {
            attrs: { visible: _vm.showQuotaSettingsInfoModel },
            on: {
              close: function ($event) {
                return _vm.showQuotaSettingsInfo(false)
              },
            },
          })
        : _vm._e(),
      _vm.isDisplayQualityScoreModal
        ? _c("QualityScoreModal", {
            attrs: {
              visible: _vm.isDisplayQualityScoreModal,
              datafileId: _vm.datafileId,
            },
            on: {
              close: function ($event) {
                _vm.isDisplayQualityScoreModal = false
              },
            },
          })
        : _vm._e(),
      _vm.isDisplayCertificationLevelModal
        ? _c("CertificationLevelsModal", {
            attrs: { visible: _vm.isDisplayCertificationLevelModal },
            on: {
              close: function ($event) {
                _vm.isDisplayCertificationLevelModal = false
              },
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }