<script>
import DatafileReportService from "@/api/services/datafileReport.service.js";
import LiveCleaningStatus from "@/shared/enums/liveCleaningStatus.js";
import { GLOBALS } from "@/shared/util/globals.js";
import ScoreThresholdInfoModal from "../modals/score-threshold-info-modal.vue";

export default {
  props: {
    datafileId: String
  },
  components: {
    ScoreThresholdInfoModal
  },
  data() {
    return {
      includedRecords: 0,
      removedRecords: 0,
      scoreThresholds: {},
      uploadMethod: "Manual Upload",
      clientName: "",
      clientLogo: null,
      companyName: "",
      companyLogo: null,
      datafileTitle: "",
      liveStatus: null,
      weighted: false,
      cardHeight: "350px",
      createdDate: "",
      /****** Operational Variables  ********/
      displayScoreThresholdInfoModal: false,
      isMobile: false
    };
  },
  computed: {
    isLiveStatusRunning() {
      return this.liveStatus === LiveCleaningStatus.running;
    }
  },
  async created() {
    await this.detectMobile();
    await this.fetchMetadata();
  },
  methods: {
    detectMobile() {
      if (window.innerWidth <= 900) {
        this.isMobile = true;
      }
    },
    async fetchMetadata() {
      const metadata = await DatafileReportService.getReportMetadata(
        this.datafileId,
        GLOBALS.reportPassword
      );

      this.includedRecords = metadata.includedRecords;
      this.removedRecords = metadata.removedRecords;
      for (const key of Object.keys(metadata.scoreThresholds)) {
        if (
          metadata.scoreThresholds[key] ||
          metadata.scoreThresholds[key] === 0
        ) {
          this.scoreThresholds[key] = metadata.scoreThresholds[key];
        }
      }
      this.uploadMethod = metadata.uploadMethod;
      this.clientName = metadata.clientName;
      this.clientLogo = metadata.clientLogo;
      this.companyName = metadata.companyName;
      this.companyLogo = metadata.companyLogo;
      this.datafileTitle = metadata.datafileTitle;
      this.weighted = metadata.weighted;
      this.liveStatus = metadata.liveStatus;
      this.createdDate = metadata.createdDate;
      this.$emit("setCertificateCode", metadata.certificateCode);
      this.$emit("setCertificateLevel", metadata.certificateLevel);
      this.$emit("setCertificateImageURL", metadata.certificateImageURL);
      this.$emit(
        "setIsDisplayQualityScoreWidgets",
        metadata.isDisplayQualityScoreWidgets
      );
      this.$emit("setLiveStatus", metadata.liveStatus);
      let title = `Data Quality Report | ${this.datafileTitle} | Redem`;
      document.title = title;
    },
    clickInfoScreThreshold() {
      this.displayScoreThresholdInfoModal = true;
    }
  }
};
</script>

<template>
  <div>
    <div class="card" v-if="!this.isMobile" :style="{ height: '350px' }">
      <div class="card-body">
        <!-- Datafile Title -->
        <div class="row card-text">
          <div class="col-md-9">
            <h5>{{ datafileTitle }}</h5>
          </div>
          <div class="col-md-3 text-right">
            <b-badge
              v-if="weighted"
              style="font-size: 12px"
              variant="primary"
              class="mr-2"
            >
              <i class="bx bx-slider mr-1"></i>
              {{ $t("datafileResultCleanedTab.metadataWidget.weightedTag") }}
            </b-badge>
            <div v-if="liveStatus">
              <b-badge
                v-if="isLiveStatusRunning"
                style="font-size: 12px"
                variant="light"
              >
                <i class="bx bxs-circle bx-flashing text-success"></i>
                <span class="text-success"> LIVE </span>
              </b-badge>
              <b-badge
                v-if="!isLiveStatusRunning"
                style="font-size: 12px"
                variant="light"
                class="ml-2"
              >
                <i class="bx bxs-flag-checkered"></i>
                <span class=""> FINISHED </span>
              </b-badge>
            </div>
          </div>
        </div>

        <!-- Datafile Companies -->
        <div class="card-text mt-4">
          <div class="row">
            <div class="col-md-6 mb-3">
              {{ $t("datafileReport.uploadedBy") }}
              <div class="mt-2">
                <img
                  v-if="companyLogo"
                  :src="companyLogo"
                  style="height: 40px;"
                />
                <img
                  v-else
                  src="https://redem-resources.s3.eu-central-1.amazonaws.com/default-images/dafault-company.jpg"
                  style="width: 30px; height: 30px;"
                />
                <br />
                {{ companyName }}
              </div>
            </div>
            <div class="col-md-6" v-if="clientName">
              {{ $t("datafileReport.createdFor") }}
              <div class="mt-2">
                <img v-if="clientLogo" :src="clientLogo" style="height: 40px" />
                <img
                  v-else
                  src="https://redem-resources.s3.eu-central-1.amazonaws.com/default-images/dafault-company.jpg"
                  style="width: 30px; height: 30px;"
                />
                <br />
                {{ clientName }}
              </div>
            </div>
          </div>
        </div>

        <hr />

        <!-- Datafile Metadata -->
        <div class="card-text mt-3">
          <div class="row">
            <div class="col-md-12 col-lg-7">
              <div class="row">
                <div class="col-md-6 mb-3">
                  <div>{{ $t("datafileReport.includedDatarecords") }}</div>
                  <div>
                    <strong>{{ includedRecords }}</strong>
                  </div>
                </div>
                <div class="col-md-6 mb-3">
                  <div>{{ $t("datafileReport.removedDatarecords") }}</div>
                  <div>
                    <strong> {{ removedRecords }}</strong>
                  </div>
                </div>
                <div class="col-md-6 mb-3">
                  <div>{{ $t("datafileReport.importMethod.label") }}</div>
                  <div>
                    <strong v-if="uploadMethod === 'API Import'">
                      {{ $t("datafileReport.importMethod.api") }}
                    </strong>
                    <strong v-if="uploadMethod === 'Manual Upload'">
                      {{ $t("datafileReport.importMethod.manual") }}
                    </strong>
                  </div>
                </div>
                <div class="col-md-6 mb-3">
                  <div>{{ $t("datafileReport.creationDate") }}</div>
                  <div>
                    <strong>{{ createdDate.slice(0, 10) }}</strong>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-12 col-lg-5 mb-3">
              <div>
                {{ $t("datafileReport.scoreThreshold.label") }}
                <i
                  class="bx bxs-info-circle ml-1 font-size-14 text-info bx-tada"
                  style="cursor: pointer"
                  @click="clickInfoScreThreshold()"
                ></i>
              </div>
              <div v-if="Object.keys(scoreThresholds).length > 0">
                <div
                  v-for="scoreType of Object.keys(scoreThresholds)"
                  :key="scoreType"
                >
                  <strong
                    >{{ $t(`datafileReport.scoreTypes.${scoreType}`) }}:
                    {{ scoreThresholds[scoreType] }}</strong
                  >
                </div>
              </div>
              <div v-if="Object.keys(scoreThresholds).length === 0">
                <strong>{{
                  $t("datafileReport.scoreThreshold.noCleaningApply")
                }}</strong>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="card" v-if="this.isMobile">
      <div class="card-body">
        <!-- Datafile Title -->
        <div class="row card-text">
          <div class="col-md-12">
            <h5>{{ datafileTitle }}</h5>
          </div>
          <div class="col-md-12">
            <b-badge
              v-if="weighted"
              style="font-size: 12px"
              variant="primary"
              class="mr-2"
            >
              <i class="bx bx-slider mr-1"></i>
              {{ $t("datafileResultCleanedTab.metadataWidget.weightedTag") }}
            </b-badge>
            <div v-if="liveStatus">
              <b-badge
                v-if="isLiveStatusRunning"
                style="font-size: 12px"
                variant="light"
              >
                <i class="bx bxs-circle bx-flashing text-success"></i>
                <span class="text-success"> LIVE </span>
              </b-badge>
              <b-badge
                v-if="!isLiveStatusRunning"
                style="font-size: 12px"
                variant="light"
                class="ml-2"
              >
                <i class="bx bxs-flag-checkered"></i>
                <span class=""> FINISHED </span>
              </b-badge>
            </div>
          </div>
        </div>

        <!-- Datafile Companies -->
        <div class="card-text mt-4">
          <div class="row">
            <div class="col-md-6 mb-3">
              {{ $t("datafileReport.uploadedBy") }}
              <div class="mt-2">
                <img
                  v-if="companyLogo"
                  :src="companyLogo"
                  style="height: 40px;"
                />
                <img
                  v-else
                  src="https://redem-resources.s3.eu-central-1.amazonaws.com/default-images/dafault-company.jpg"
                  style="width: 30px; height: 30px;"
                />
                <br />
                {{ companyName }}
              </div>
            </div>
            <div class="col-md-6" v-if="clientName">
              {{ $t("datafileReport.createdFor") }}
              <div class="mt-2">
                <img
                  v-if="clientLogo"
                  :src="clientLogo"
                  style="height: 40px;"
                />
                <img
                  v-else
                  src="https://redem-resources.s3.eu-central-1.amazonaws.com/default-images/dafault-company.jpg"
                  style="width: 30px; height: 30px;"
                />
                <br />
                {{ clientName }}
              </div>
            </div>
          </div>
        </div>

        <hr />

        <!-- Datafile Metadata -->
        <div class="card-text mt-3">
          <div class="row">
            <div class="col-md-12 col-lg-7">
              <div class="row">
                <div class="col-md-6 mb-3">
                  <div>{{ $t("datafileReport.includedDatarecords") }}</div>
                  <div>
                    <strong>{{ includedRecords }}</strong>
                  </div>
                </div>
                <div class="col-md-6 mb-3">
                  <div>{{ $t("datafileReport.removedDatarecords") }}</div>
                  <div>
                    <strong> {{ removedRecords }}</strong>
                  </div>
                </div>
                <div class="col-md-6 mb-3">
                  <div>{{ $t("datafileReport.importMethod.label") }}</div>
                  <div>
                    <strong v-if="uploadMethod === 'API Import'">
                      {{ $t("datafileReport.importMethod.api") }}
                    </strong>
                    <strong v-if="uploadMethod === 'Manual Upload'">
                      {{ $t("datafileReport.importMethod.manual") }}
                    </strong>
                  </div>
                </div>
                <div class="col-md-6 mb-3">
                  <div>{{ $t("datafileReport.creationDate") }}</div>
                  <div>
                    <strong>{{ createdDate.slice(0, 10) }}</strong>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-12 col-lg-5 mb-3">
              <div>
                {{ $t("datafileReport.scoreThreshold.label") }}
                <i
                  class="bx bxs-info-circle ml-1 font-size-14 text-info bx-tada"
                  style="cursor: pointer"
                  @click="clickInfoScreThreshold()"
                ></i>
              </div>
              <div v-if="Object.keys(scoreThresholds).length > 0">
                <div
                  v-for="scoreType of Object.keys(scoreThresholds)"
                  :key="scoreType"
                >
                  <strong
                    >{{ $t(`datafileReport.scoreTypes.${scoreType}`) }}:
                    {{ scoreThresholds[scoreType] }}</strong
                  >
                </div>
              </div>
              <div v-if="Object.keys(scoreThresholds).length === 0">
                <strong>{{
                  $t("datafileReport.scoreThreshold.noCleaningApply")
                }}</strong>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Score Threshold Info Modal -->
    <ScoreThresholdInfoModal
      v-if="displayScoreThresholdInfoModal"
      :visible="displayScoreThresholdInfoModal"
      :scoreThresholds="scoreThresholds"
      @close="displayScoreThresholdInfoModal = false"
    />
  </div>
</template>
