var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-modal",
    {
      attrs: {
        centered: "",
        "hide-footer": "",
        "no-close-on-backdrop": "",
        size: "xl",
        scrollable: "",
        title: this.$t("datafileReport.qualityScoresModal.title"),
      },
      on: {
        hide: function ($event) {
          return _vm.clickClose()
        },
      },
      model: {
        value: _vm.showModal,
        callback: function ($$v) {
          _vm.showModal = $$v
        },
        expression: "showModal",
      },
    },
    [
      _c("div", { staticClass: "row mb-3" }, [
        _c("div", { staticClass: "col-md-12" }, [
          _vm._v(
            " " +
              _vm._s(_vm.$t("datafileReport.qualityScoresModal.description")) +
              " "
          ),
        ]),
      ]),
      this.algorithms
        ? _c("div", { staticClass: "row" }, [
            _vm.algorithms.OES
              ? _c(
                  "div",
                  { staticClass: "col-md-4" },
                  [
                    _c("OpenEndedScoreWidget", {
                      attrs: { datafileId: _vm.datafileId },
                      on: { finishLoading: _vm.finishLoadingOES },
                    }),
                    _vm.algorithms.OES && !this.isDisplayOES && !this.errorOES
                      ? _c("LoadingCircle")
                      : _vm._e(),
                    this.errorOES ? _c("APIError") : _vm._e(),
                  ],
                  1
                )
              : _vm._e(),
            _vm.algorithms.TS
              ? _c(
                  "div",
                  { staticClass: "col-md-4" },
                  [
                    _c("TimeScoreWidget", {
                      attrs: { datafileId: _vm.datafileId },
                      on: { finishLoading: _vm.finishLoadingTS },
                    }),
                    _vm.algorithms.TS && !this.isDisplayTS && !this.errorTS
                      ? _c("LoadingCircle")
                      : _vm._e(),
                    this.errorTS ? _c("APIError") : _vm._e(),
                  ],
                  1
                )
              : _vm._e(),
            _vm.algorithms.IBS
              ? _c(
                  "div",
                  { staticClass: "col-md-4" },
                  [
                    _c("ItemBatteryScoreWidget", {
                      attrs: { datafileId: _vm.datafileId },
                      on: { finishLoading: _vm.finishLoadingIBS },
                    }),
                    _vm.algorithms.IBS && !this.isDisplayIBS && !this.errorIBS
                      ? _c("LoadingCircle")
                      : _vm._e(),
                    this.errorIBS ? _c("APIError") : _vm._e(),
                  ],
                  1
                )
              : _vm._e(),
            _vm.algorithms.PS
              ? _c(
                  "div",
                  { staticClass: "col-md-4" },
                  [
                    _c("PredictionScoreWidget", {
                      attrs: { datafileId: _vm.datafileId },
                      on: { finishLoading: _vm.finishLoadingPS },
                    }),
                    _vm.algorithms.PS && !this.isDisplayPS && !this.errorPS
                      ? _c("LoadingCircle")
                      : _vm._e(),
                    this.errorPS ? _c("APIError") : _vm._e(),
                  ],
                  1
                )
              : _vm._e(),
            _vm.algorithms.CS
              ? _c(
                  "div",
                  { staticClass: "col-md-4" },
                  [
                    _c("CertinityScoreWidget", {
                      attrs: { datafileId: _vm.datafileId },
                      on: { finishLoading: _vm.finishLoadingCS },
                    }),
                    _vm.algorithms.CS && !this.isDisplayCS && !this.errorCS
                      ? _c("LoadingCircle")
                      : _vm._e(),
                    this.errorCS ? _c("APIError") : _vm._e(),
                  ],
                  1
                )
              : _vm._e(),
            _vm.algorithms.IS
              ? _c(
                  "div",
                  { staticClass: "col-md-4" },
                  [
                    _c("InformationScoreWidget", {
                      attrs: { datafileId: _vm.datafileId },
                      on: { finishLoading: _vm.finishLoadingIS },
                    }),
                    _vm.algorithms.IS && !this.isDisplayIS && !this.errorIS
                      ? _c("LoadingCircle")
                      : _vm._e(),
                    this.errorIS ? _c("APIError") : _vm._e(),
                  ],
                  1
                )
              : _vm._e(),
            _vm.algorithms.SDS
              ? _c(
                  "div",
                  { staticClass: "col-md-4" },
                  [
                    _c("SocialDesirabilityScoreWidget", {
                      attrs: { datafileId: _vm.datafileId },
                      on: { finishLoading: _vm.finishLoadingSDS },
                    }),
                    _vm.algorithms.SDS && !this.isDisplaySDS && !this.errorSDS
                      ? _c("LoadingCircle")
                      : _vm._e(),
                    this.errorSDS ? _c("APIError") : _vm._e(),
                  ],
                  1
                )
              : _vm._e(),
          ])
        : _vm._e(),
      !this.algorithms
        ? _c(
            "div",
            { staticClass: "min-height col-12 text-center p-5" },
            [
              _c("b-spinner", {
                staticClass: "m-2",
                staticStyle: { width: "5rem", height: "5rem" },
                attrs: { variant: "mid", role: "status" },
              }),
            ],
            1
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }