<script>
export default {
  props: {
    visible: Boolean,
    scoreThresholds: Object
  },
  data() {
    return {};
  },
  computed: {
    showModal: {
      get() {
        return this.visible;
      },
      set(value) {
        if (!value) {
          this.$emit("close");
        }
      }
    }
  },
  methods: {
    closeMode() {
      this.$emit("close");
    }
  }
};
</script>

<template>
  <b-modal centered hide-footer hide-header v-model="showModal">
    <div class="row">
      <!-- Title -->
      <div class="col-md-12 mb-3">
        <div class="float-right">
          <i
            class="bx bx-x font-size-20"
            style="cursor: pointer"
            @click="closeMode()"
          ></i>
        </div>
        <h5 class="">
          {{ $t("datafileReport.scoreThresholdInfoModal.title") }}
        </h5>
      </div>

      <!-- Description -->
      <div class="col-md-12">
        {{ $t("datafileReport.scoreThresholdInfoModal.description") }}
      </div>

      <!-- Scores -->
      <div class="col-md-12 mt-3">
        <div v-for="scoreType of Object.keys(scoreThresholds)" :key="scoreType"  class="mb-2">
          <div v-if="scoreType === 'R'">
            <label>Redem-Score </label
            >{{ $t("datafileReport.scoreThresholdInfoModal.rScore") }}
          </div>
          <div v-if="scoreType === 'TS'">
            <label>Time-Score </label
            >{{ $t("datafileReport.scoreThresholdInfoModal.timeScore") }}
          </div>
          <div v-if="scoreType === 'OES'">
            <label>Open-Ended-Score </label
            >{{ $t("datafileReport.scoreThresholdInfoModal.openEndedScore") }}
          </div>
          <div v-if="scoreType === 'PS'">
            <label>Prediction-Score </label
            >{{ $t("datafileReport.scoreThresholdInfoModal.predictionScore") }}
          </div>
          <div v-if="scoreType === 'CS'">
            <label>Certainty-Score </label
            >{{ $t("datafileReport.scoreThresholdInfoModal.certaintyScore") }}
          </div>
          <div v-if="scoreType === 'IBS'">
            <label>Grid-Question-Score </label
            >{{ $t("datafileReport.scoreThresholdInfoModal.itemBatteryScore") }}
          </div>
        </div>
      </div>
    </div>
  </b-modal>
</template>
